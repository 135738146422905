<template>
  <div>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="12" md="6" class="rounded-lg mt-15">
              <v-card v-if="!error">
                <v-card-title>
                  Einladung zum Organisationsbeitritt
                </v-card-title>
                <v-card-text v-if="authState">
                  <div class="subtitle-1">
                    Du wurdest zum Beitritt einer Organisation in Mira One
                    eingeladen! Um der Organisation beizutreten benötigest Du
                    ein Benutzerkonto. Wenn Du der Organisation beitrittst, wird
                    Dein Name, Deine E-Mail-Adresse und ggf. weitere Details mit
                    der Organisation geteilt.
                  </div>
                </v-card-text>
                <v-card-text v-if="!authState">
                  <v-alert type="error" outlined class="pa-6">
                    <div class="title">Anmeldung erfolderlich</div>
                    <div>
                      Um einer Organisation beizutreten, musst Du Dich zunächst
                      mit Deinem Benutzerkonto anmelden. Wenn Du noch kein
                      Benutzerkonto besitzt, kannst Du nun ein Konto erstellen.
                    </div>
                    <v-divider
                      class="my-4 error"
                      style="opacity: 0.22"
                    ></v-divider>
                    <v-btn
                      color="error"
                      outlined
                      class="mr-6"
                      link
                      :to="{ name: 'auth-login' }"
                    >
                      Anmelden
                    </v-btn>
                    <v-btn
                      color="error"
                      outlined
                      link
                      :to="{ name: 'auth-register' }"
                    >
                      Konto erstellen
                    </v-btn>
                  </v-alert>
                </v-card-text>
                <v-card-actions v-if="authState">
                  <v-btn
                    dark
                    color="error"
                    depressed
                    :disabled="loadingBtn"
                    @click="snackbar = true"
                  >
                    <v-icon left>mdi-close-circle</v-icon>
                    Abbrechen
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    dark
                    color="success"
                    depressed
                    :loading="loadingBtn"
                    @click="joinOrganization()"
                  >
                    Beitreten
                    <v-icon right>mdi-check-circle</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
              <div v-else>
                <v-alert
                  type="warning"
                  colored-border
                  border="top"
                  class="pa-6"
                  elevation="2"
                >
                  <div class="title">Fehler</div>
                  <div>
                    Diese Einladung ist ungültig, abgelaufen oder wurde
                    widerrufen. Prüfe, ob der Link korrekt ist oder benachrichte
                    Deinen Administrator und fordere eine neue Einladung an.
                  </div>
                </v-alert>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <v-snackbar multi-line v-model="snackbar" timeout="6000">
        {{ snackbarText }}
      </v-snackbar>
    </section>
    <Loading v-if="loading" overlay="true"></Loading>
  </div>
</template>

<script>
import Loading from "@/components/_system/helpers/Loading.vue";
import { auth, functions } from "@/firebase";

export default {
  name: "organization-join",
  components: {
    Loading,
  },
  data() {
    return {
      dialog: false,
      dialogTitle: "Du wurdest zu einer Organisation eingeladen!",
      loading: true,
      loadingBtn: false,
      error: false,
      snackbar: false,
      snackbarText: "Schließe dieses Fenster, um die Einladung zu ignorieren.",
    };
  },
  computed: {
    authState() {
      if (!auth.currentUser) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    if (!auth.currentUser) {
      console.log("unauthenticated");
      setTimeout(this.changeLoading, 2400);
    } else {
      console.log("authenticated");
      this.checkInvite();
      // If invite is invalid -> Change error to true
      setTimeout(this.changeLoading, 2400);
    }
  },
  methods: {
    changeLoading() {
      this.loading = !this.loading;
    },
    checkInvite() {
      // const func = functions.httpsCallable("organization-orgNew");
      // func({
      //   organizationId: this.$route.params.organizationId,
      //   inviteID: this.$route.params.inviteId,
      //   inviteKEY: this.$route.params.inviteKey,
      // })
      //   .then((result) => {
      //     console.log(result.data);
      //   })
      //   .catch(function(error) {
      //     console.error("Error: ", error);
      //   });
    },
    joinOrganization() {
      console.log(
        this.$route.params.organizationId,
        " ",
        this.$route.params.inviteId,
        " ",
        this.$route.params.inviteKey
      );
      this.loadingBtn = true;
      const func = functions.httpsCallable("organization-userJoin");
      func({
        organizationId: this.$route.params.organizationId,
        inviteId: this.$route.params.inviteId,
        inviteKey: this.$route.params.inviteKey,
      })
        .then((result) => {
          console.log(result.data);
          if (result.data.success) {
            this.snackbarText =
              "Beitritt erfolgreich! Du bist nun Mitglied der Organisation.";
            this.snackbar = true;
            setTimeout(
              this.$router.push({
                name: "my-organization-dashboard",
                params: { organizationId: this.$route.params.organizationId },
              }),
              2500
            );
          } else {
            this.snackbarText =
              "Fehler beim Beitritt: Bitte kontaktiere den Support.";
            this.snackbar = true;
          }
        })
        .catch(function (error) {
          console.error("Error: ", error);
        });
    },
  },
};
</script>
