<template>
  <div>
    <NavigationBar
      titel="Neue Organisation erstellen"
      showBackBtn
    ></NavigationBar>
    <v-container>
      <v-row justify="center" align="center">
        <v-col cols="12">
          <v-stepper v-model="stepper" vertical>
            <v-stepper-step :complete="stepper > 1" step="1">
              Allgemeine Informationen
            </v-stepper-step>

            <v-stepper-content step="1">
              <v-card flat>
                <v-card-text>
                  <v-container>
                    <v-form
                      v-model="form1IsValid"
                      @submit.prevent="submitData"
                      class="col s8"
                    >
                      <v-row>
                        <v-col cols="12" sm="12" md="8">
                          <v-text-field
                            label="Vollständige Organisationsbezeichnung"
                            hint="Die Bezeichnung der Organisation sollte keine Abkürzungen enthalten und den vollständigen Namen wiederspiegeln. Beispiel: Freiwillige Feuerwehr Musterstadt"
                            :rules="[rules.required]"
                            required
                            persistent-hint
                            outlined
                            v-model.trim="organization.fullName"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="4">
                          <v-text-field
                            label="Organisationsnummer"
                            hint="Gib Deine Organisations-, Standort-, Einheitsnummer an, sofern Du diese besitzt. Beispiel: 1234"
                            :rules="[rules.required]"
                            required
                            persistent-hint
                            outlined
                            v-model.trim="organization.stationNumber"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            label="Anzeigename"
                            hint="Der Anzeigename wird in der Anwendung angezeigt."
                            required
                            :rules="[rules.required]"
                            persistent-hint
                            outlined
                            v-model.trim="organization.displayName"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            label="Abteilung/Behörde"
                            outlined
                            v-model.trim="organization.department"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    right
                    color="primary"
                    :disabled="!form1IsValid"
                    @click="stepper++"
                  >
                    Weiter
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <v-stepper-step :complete="stepper > 2" step="2">
              Kontakt- und Standortdaten
            </v-stepper-step>

            <v-stepper-content step="2">
              <v-card flat>
                <v-card-text>
                  <v-container>
                    <v-form
                      v-model="form2IsValid"
                      @submit.prevent="submitData"
                      class="col s8"
                    >
                      <v-row>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            label="Straße Hausnummer"
                            hint="Beispiel: Musterstraße 123"
                            required
                            :rules="[rules.required]"
                            outlined
                            v-model.trim="organization.location.adress.street"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            label="Adresszusatz"
                            outlined
                            v-model.trim="
                              organization.location.adress.additionalInfo
                            "
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <v-text-field
                            label="Postleitzahl"
                            hint="Beispiel: 12345"
                            required
                            :rules="[rules.required]"
                            outlined
                            v-model.trim="
                              organization.location.adress.postalCode
                            "
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <v-text-field
                            label="Stadt"
                            hint="Beispiel: Musterstadt"
                            required
                            :rules="[rules.required]"
                            outlined
                            v-model.trim="organization.location.adress.city"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-select
                            :items="['Deutschland']"
                            label="Land"
                            hint="Musterland"
                            disabled
                            required
                            :rules="[rules.required]"
                            outlined
                            v-model="organization.location.adress.country"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            label="E-Mail Adresse"
                            type="email"
                            hint="Beispiel: mail@email.com"
                            required
                            :rules="[rules.required, rules.email]"
                            outlined
                            prepend-inner-icon="mdi-email"
                            v-model.trim="organization.contact.email"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <v-text-field
                            label="Telefon"
                            hint="Beispiel: +49 (0)30 1234567 8"
                            type="tel"
                            required
                            :rules="[rules.required]"
                            outlined
                            prepend-inner-icon="mdi-phone"
                            v-model.trim="organization.contact.phone"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <v-text-field
                            label="Fax"
                            hint="Beispiel: +49 (0)30 1234567 9"
                            outlined
                            prepend-inner-icon="mdi-fax"
                            v-model.trim="organization.contact.fax"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model.trim="
                              organization.location.geopoint.latitude
                            "
                            outlined
                            required
                            :rules="[rules.required, rules.latitude]"
                            label="Breitengrad"
                            prepend-inner-icon="mdi-latitude"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model.trim="
                              organization.location.geopoint.longitude
                            "
                            outlined
                            required
                            :rules="[rules.required, rules.longitude]"
                            label="Längengrad"
                            prepend-inner-icon="mdi-longitude"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-btn depressed plain text @click="stepper = 1">
                    Zurück
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    right
                    color="primary"
                    :disabled="!form2IsValid"
                    @click="stepper++"
                  >
                    Weiter
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <v-stepper-step :complete="stepper > 3" step="3">
              Organisationsstruktur
            </v-stepper-step>

            <v-stepper-content step="3">
              <v-card flat>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-alert outlined type="warning" icon="mdi-alert">
                          Diese Konfiguration ist deaktiviert. Du musst
                          mindestens eine weitere Organisation besitzen, um
                          Einstellungen vorzunehmen.
                        </v-alert>
                        <!-- <p class="subtitle-1">Eine Organisation ist grundsätzlich eine eigene und eigenständige Einheit. Um komplexe Organisationsstrukturen abzubilden, lassen sich Einstellungen und Daten vererben. Dafür kann eine Organisation einer anderen Organisation untergeordet oder übergeordnet werden. Um die optimale Organisationsstruktur und -einstellung zu finden, sind verschiedene Beispiele im <a href="#" class="text-decoration-none">Support-Portal</a> visualisiert und erklärt.</p>
                        <p class="subtitle-1">Wähle nachfolgend eine Einstellung aus, um die Struktur für Deine Organisation festzulegen.</p> -->
                      </v-col>
                      <!-- <v-col
                          cols="12"
                          sm="12"
                          md="12"
                        >
                          <v-radio-group
                            v-model="radios"
                            mandatory
                          >
                            <v-radio
                              label="Organisation als eigenständige unabhängige Einheit"
                              value=false
                            ></v-radio>
                            <v-radio
                              label="Organisation als Ober-/Unterorganisation (Vererbung von Einstellungen/Informationen)"
                              value=true
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                        >
                          <p class="subtitle-1">Wenn Sie mehrere Organisationen, die einer komplexen Struktur angehören, anlegen möchten und dies ist die erste (oberste) Organisation, wählen Sie zunächst die erste Option.</p>
                        </v-col>
                        <v-col v-if="radios"
                          cols="12"
                          sm="12"
                          md="12"
                        >
                          <p class="subtitle-1">Geben Sie die ID der Organisation ein, von der Einstellungen- sowie Informationen vererbt werden sollen. Die ID einer Organisation müssen Sie beim Inhaber oder Administrator der entsprechenden Organisation anfragen. Nachdem wir Ihre Organisation zunächst vorläufig erstellt haben, wartet das System auf die Freigabe deiner Anfrage. Diese wird durch die von Ihnen angegebene Organisation erteilt.</p>
                        </v-col>
                        <v-col v-if="radios"
                          cols="12"
                          sm="12"
                          md="4"
                        >
                          <v-text-field
                            label="ID der Organisation"
                            required
                            outlined
                            v-model="strukturID"
                          ></v-text-field>
                        </v-col> -->
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-btn depressed plain text @click="stepper = 2">
                    Zurück
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn depressed right color="primary" @click="stepper++">
                    Weiter
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <v-stepper-step :complete="stepper > 4" step="4">
              Abrechnung
            </v-stepper-step>

            <v-stepper-content step="4">
              <v-card flat>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-alert outlined icon="mdi-alert" type="warning">
                          Mit Erstellen der Organisation beginnt ein zeitlich
                          begrenzter kostenfreier Testzeitraum.
                          Zahlungsinformationen und Abrechnungseinstellungen
                          können in den Einstellungen der Organisation angegeben
                          werden, sobald die Organisation erfolgreich angelegt
                          wurde.
                        </v-alert>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
              <v-card-actions>
                <v-btn depressed plain text @click="stepper = 3">
                  Zurück
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn depressed right color="primary" @click="stepper++">
                  Weiter
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-stepper-content>

            <v-stepper-step :complete="stepper > 5" step="5">
              Zusammenfassung
            </v-stepper-step>
            <v-stepper-content step="5">
              <v-card flat>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <p class="subtitle-1">
                          Das Erstellen der Organisation ist fast abgeschlossen.
                          Bitte prüfe nachfolgend die eingegeben Daten und
                          korrigiere, sofern notwendig.
                        </p>
                        <p class="subtitle-2">
                          {{ organization.fullName }}<br />
                          {{ organization.displayName }}<br />
                          {{ organization.department }},
                          {{ organization.stationNumber }}
                          <br />
                          {{ organization.location.adress.street }}<br />
                          {{ organization.location.adress.additionalInfo
                          }}<br />
                          {{ organization.location.adress.postalCode }}
                          {{ organization.location.adress.city }},
                          {{ organization.location.adress.country }}<br />

                          Breitengrad:
                          {{ organization.location.geopoint.latitude }}<br />
                          Längengrad:
                          {{ organization.location.geopoint.longitude }}<br />

                          E-Mail: {{ organization.contact.email }}<br />
                          Telefon: {{ organization.contact.phone }}<br />
                          Telefax: {{ organization.contact.fax }}<br />
                        </p>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-btn text :disabled="loading" @click="stepper = 1">
                    <v-icon left>mdi-pencil</v-icon>
                    Daten korrigieren
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    right
                    :loading="loading"
                    color="primary"
                    @click="createOrganization()"
                  >
                    Organisation erstellen
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { functions } from "@/firebase";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";

export default {
  name: "organization-new",
  components: {
    NavigationBar,
  },
  data() {
    return {
      stepper: 1,
      loading: false,

      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
        email: (value) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          ) || "Ungültige E-Mail-Adresse",
        latitude: (value) => {
          const pattern =
            /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
          return pattern.test(value) || "Zahl muss zwischen -90 und 90 liegen.";
        },
        longitude: (value) => {
          const pattern =
            /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
          return (
            pattern.test(value) || "Zahl muss zwischen -180 und 180 liegen."
          );
        },
      },
      form1IsValid: false,
      form2IsValid: false,

      organization: {
        displayName: null,
        fullName: null,
        department: null,
        stationNumber: null,
        location: {
          geopoint: {
            longitude: null,
            latitude: null,
          },
          adress: {
            street: null,
            additionalInfo: null,
            city: null,
            postalCode: null,
            country: "Deutschland",
          },
        },
        contact: {
          email: null,
          phone: null,
          fax: null,
        },
        timezone: null, //organization.timezone,
      },
    };
  },
  methods: {
    createOrganization() {
      this.loading = true;
      const createOrganization = functions.httpsCallable(
        "organization-createOrganization"
      );
      createOrganization({
        licenseKey: this.$route.query.licenseKey,
        displayName: this.organization.displayName,
        fullName: this.organization.fullName,
        department: this.organization.department,
        stationNumber: this.organization.stationNumber,
        location: {
          geopoint: {
            longitude: this.organization.location.geopoint.longitude,
            latitude: this.organization.location.geopoint.latitude,
          },
          adress: {
            street: this.organization.location.adress.street,
            additionalInfo: this.organization.location.adress.additionalInfo,
            city: this.organization.location.adress.city,
            postalCode: this.organization.location.adress.postalCode,
            country: this.organization.location.adress.country,
          },
        },
        contact: {
          email: this.organization.contact.email,
          phone: this.organization.contact.phone,
          fax: this.organization.contact.fax,
        },
      }).then((result) => {
        console.log(result);
        if (result.data.success) {
          this.$router.push({
            name: "admin-config-tab-general",
            params: {
              organizationId: result.data.organizationId,
            },
          });
        } else {
          alert(
            "Fehler beim Erstellen der Organisation! Versuche es später erneut oder kontaktiere den Support!"
          );
        }
      });
    },
  },
};
</script>
